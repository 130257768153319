import film from '../Assets/film.png';
export const interviewOptions = [
  {
    type: 'Computer Vision',
    image: film,
    coupon: '1',
    jd: 'about computer vision developement',
  },
  {
    type: 'Data Science',
    image: film,
    coupon: '2',
    jd: 'about data science',
  },
  {
    type: 'Machine Learning',
    image: film,
    coupon: '3',
    jd: 'about ML',
  },
  {
    type: 'Product Manager',
    image: film,
    coupon: '4',
    jd: 'about product manager',
  },
  {
    type: 'Robotics',
    image: film,
    coupon: '5',
    jd: 'about Robotics',
  },
  {
    type: 'Software Development',
    image: film,
    coupon: '6',
    jd: 'about SDE',
  },
  {
    type: 'UX',
    image: film,
    coupon: '7',
    jd: 'about UX',
  },
  {
    type: 'Other',
    image: film,
    coupon: '8',
    jd: 'get info from resume',
  },
];

export const getCouponInfoFromCode = (couponCode) => {
  let jdString = null;
  let type = null;

  interviewOptions.forEach((interview) => {
    if (interview.coupon === `${couponCode}`) {
      jdString = interview.jd;
      type = interview.type;
    }
  });

  return { jdString, type };
};

export const checkCouponCode = (couponCode) => {
  let isValid = false;
  interviewOptions.forEach((option) => {
    if (option.coupon === `${couponCode}`) {
      isValid = true;
    }
  });
  return isValid;
};
