import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getRequest } from '../Util/apiHelper';

const useFetchInterviews = (token) => {
  const [interviews, setInterviews] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchInterviews = async () => {
      try {
        setLoading(true);
        const result = await getRequest('api/v1/myInterviews/all', token);
        const { interviews } = result.data;
        setInterviews(interviews);
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch interviews:', error);
      }
    };

    if (token) {
      fetchInterviews();
    }
  }, [token]);

  return { interviews, loading };
};

useFetchInterviews.propTypes = {
  token: PropTypes.string.isRequired,
};

export default useFetchInterviews;
