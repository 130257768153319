import React from 'react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
} from '@tanstack/react-table';
import useFetchInterviews from '../../ApiCall/useFetchInterviews';
import { useSelector } from 'react-redux';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { CiBookmark } from 'react-icons/ci';
import { GoDownload } from 'react-icons/go';
import { GrLinkNext, GrLinkPrevious } from 'react-icons/gr';
import InterviewNotFound from './InterviewNotFound';
import Loaders from '../misc/Loaders';
import { downloadMetadataFromS3 } from '../../Util/s3DownloadHelper';

const InterviewTable = () => {
  const user = useSelector((state) => state.user.user);
  const token = user?.token;
  const { interviews: data, loading } = useFetchInterviews(token);
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 7,
  });

  // Format date helper function
  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    const formattedTime = date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    });
    return `${formattedDate} at ${formattedTime}`;
  };

  // Columns definition for the table
  const columns = [
    {
      header: 'Time',
      accessorKey: 'startdatetime',
      cell: ({ getValue }) => {
        const dateValue = getValue();
        return formatDate(dateValue);
      },
    },
    { header: 'Role', accessorKey: 'interview_type' },
    {
      header: 'Actions',
      accessorKey: '',
      cell: ({ row }) => (
        <div className="flex justify-end space-x-2">
          <button
            onClick={() => handleDelete(row.original)}
            aria-label="Delete"
            className="p-1 hover:bg-gray-200 rounded"
          >
            <RiDeleteBin6Line size={20} />
          </button>
          <button
            onClick={() => handleBookmark(row.original)}
            aria-label="Bookmark"
            className="p-1 hover:bg-gray-200 rounded"
          >
            <CiBookmark size={20} />
          </button>
          <button
            onClick={() => handleDownload(row.original)}
            aria-label="Download"
            className="p-1 hover:bg-gray-200 rounded"
          >
            <GoDownload size={20} />
          </button>
        </div>
      ),
    },
  ];

  const handleDelete = (rowData) => {};
  const handleBookmark = (rowData) => {};
  const handleDownload = (rowData) => {
    const { foldername, bucketname } = rowData;
    downloadMetadataFromS3(
      foldername,
      bucketname,
      'feedback.pdf',
      'application/pdf',
      'The feedback has not been generated. Please wait for 3 hours or contact support.'
    );
  };

  // Initialize table instance
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      pagination,
    },
  });

  const totalPages = Math.ceil(
    table.getPrePaginationRowModel().rows.length / pagination.pageSize
  );

  const PAGE_BUTTONS_VISIBLE = 5;
  const HALF_BUTTONS = Math.floor(PAGE_BUTTONS_VISIBLE / 2);

  let startPage = Math.max(0, pagination.pageIndex - HALF_BUTTONS);
  let endPage = Math.min(totalPages - 1, pagination.pageIndex + HALF_BUTTONS);

  if (pagination.pageIndex - HALF_BUTTONS < 0) {
    endPage = Math.min(totalPages - 1, PAGE_BUTTONS_VISIBLE - 1);
  }
  if (pagination.pageIndex + HALF_BUTTONS >= totalPages) {
    startPage = Math.max(0, totalPages - PAGE_BUTTONS_VISIBLE);
  }

  // Return the loading or not found component if applicable
  if (loading) return <Loaders />;
  if (!data.length) return <InterviewNotFound />;

  return (
    <div className="p-2 select-none text-gray-700">
      <div className="overflow-x-auto rounded-lg border overflow-hidden">
        <table className="min-w-full table-auto">
          <thead className="bg-gray-200">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className={`text-left text-sm p-[10px] px-20 ${
                      header.column.columnDef.accessorKey === ''
                        ? 'text-right'
                        : ''
                    }`}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className={`text-left py-5 px-12 text-sm border-t border-b ${
                      cell.column.columnDef.accessorKey === ''
                        ? 'text-right'
                        : ''
                    }`}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={columns.length} className="p-2 text-xs">
                <div className="flex items-center justify-between space-x-2">
                  <button
                    onClick={() =>
                      setPagination((prev) => ({
                        ...prev,
                        pageIndex: prev.pageIndex - 1,
                      }))
                    }
                    disabled={pagination.pageIndex === 0}
                    className="px-4 py-2 border rounded hover:bg-gray-100 w-[120px] flex items-center justify-center"
                  >
                    <GrLinkPrevious />
                    <span className="ml-1">Previous</span>
                  </button>

                  <div className="flex items-center space-x-1">
                    {startPage > 0 && (
                      <>
                        <button
                          onClick={() =>
                            setPagination((prev) => ({
                              ...prev,
                              pageIndex: 0,
                            }))
                          }
                          className="py-2 rounded hover:bg-gray-300"
                        >
                          1
                        </button>
                        {startPage > 1 && (
                          <span className="py-2 text-gray-500">...</span>
                        )}
                      </>
                    )}

                    {[...Array(endPage - startPage + 1).keys()].map(
                      (offset) => {
                        const pageIndex = startPage + offset;
                        return (
                          <button
                            key={pageIndex}
                            onClick={() =>
                              setPagination((prev) => ({
                                ...prev,
                                pageIndex,
                              }))
                            }
                            disabled={pageIndex === pagination.pageIndex}
                            className={`px-4 py-2 rounded ${
                              pageIndex === pagination.pageIndex
                                ? 'bg-[#F9F5FF] text-[#5b5bf7]'
                                : 'hover:bg-gray-100'
                            }`}
                          >
                            {pageIndex + 1}
                          </button>
                        );
                      }
                    )}

                    {endPage < totalPages - 1 && (
                      <>
                        {endPage < totalPages - 2 && (
                          <span className="px-4 py-2 text-gray-500">...</span>
                        )}
                        <button
                          onClick={() =>
                            setPagination((prev) => ({
                              ...prev,
                              pageIndex: totalPages - 1,
                            }))
                          }
                          className="px-4 py-2 rounded hover:bg-gray-300"
                        >
                          {totalPages}
                        </button>
                      </>
                    )}
                  </div>

                  <button
                    onClick={() =>
                      setPagination((prev) => ({
                        ...prev,
                        pageIndex: prev.pageIndex + 1,
                      }))
                    }
                    disabled={pagination.pageIndex === totalPages - 1}
                    className="px-4 py-2 border rounded hover:bg-gray-100 w-[120px] flex items-center justify-center"
                  >
                    <span className="mr-1">Next</span>
                    <GrLinkNext />
                  </button>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default InterviewTable;
