import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

const Loaders = ({
  loading = true,
  color = '#FFFFFF',
  size = 18,
  text = 'Loading...',
  textStyle = {},
  loaderStyle = {},
}) => {
  return (
    <div className="flex flex-col items-center" style={loaderStyle}>
      <ClipLoader
        color={color}
        loading={loading}
        size={size}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
      {text && <div style={textStyle}>{text}</div>}
    </div>
  );
};

export default Loaders;
