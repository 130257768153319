import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { server } from '../../../Util/constants';
import LogoAndName from './../../../Assets/LogoAndName.png';
import { IoMenu } from 'react-icons/io5';

const MobileHeader = () => {
  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate();
  const [scrolled, setScrolled] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [openSMenu, setOpenSMenu] = useState(false);
  const menuRef = useRef(null);
  const openTheMenu = () => {
    setOpenMenu(!openMenu);
  };
  const open = () => {
    setOpenSMenu(!openSMenu);
  };
  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        open();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  useEffect(() => {
    if (openSMenu) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [openSMenu]);

  const isLoggedIn = user ? true : false;
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        open();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  const scrollToSection = (sectionId) => {
    document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
    setOpenMenu(false);
    setOpenSMenu(false);
  };

  const openScrollMenu = () => {
    setScrolled(true);
    setOpenMenu(true);
  };

  return (
    <div className="bg-white">
      {scrolled && (
        <div className="fixed top-0 left-0 right-0 mx-7 bg-white pt-2 text-black z-50 rounded-lg mt-5">
          <div className="flex justify-between items-center text-white p-2 -pt-8">
            <div className="flex">
              <img
                src={LogoAndName}
                alt="Logo"
                className="h-10"
                onClick={() => window.location.reload()}
                style={{ cursor: 'pointer' }}
              />
            </div>
            <div className="flex">
              <button
                className="bg-[#5b5bf7] font-bold text-sm rounded-md h-10 p-1 px-3 flex items-center justify-center"
                onClick={() => {
                  if (isLoggedIn) {
                    navigate('/interview');
                  } else {
                    window.open(`${server}api/v1/auth/google`, '_self');
                  }
                }}
              >
                Join the waitlist
              </button>
              <div>
                <div className="flex items-center justify-center h-10 w-10 rounded-md mx-2">
                  <IoMenu
                    className="h-8 w-8 text-black"
                    onClick={openTheMenu}
                  />
                </div>
                <div>
                  {openMenu && (
                    <div className="text-black absolute right-0 w-full bg-white rounded-lg -mt-1">
                      <ul className="p-2 float-right">
                        <li
                          className="py-1 px-2 cursor-pointer"
                          onClick={() => scrollToSection('benefits')}
                        >
                          Benefits
                        </li>
                        <li
                          className="py-1 px-2 cursor-pointer"
                          onClick={() => scrollToSection('how-it-works')}
                        >
                          How it Works
                        </li>
                        <li
                          className="py-1 px-2 cursor-pointer"
                          onClick={() => scrollToSection('faqs')}
                        >
                          FAQs
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {!scrolled && (
        <div className="flex justify-between items-center bg-[#01011d] text-white p-2 pt-8">
          <div className="flex">
            <img
              src={LogoAndName}
              alt="Logo"
              className="h-10"
              onClick={() => window.location.reload()}
              style={{ cursor: 'pointer' }}
            />
          </div>
          <div className="flex">
            <button
              className="bg-[#5b5bf7] font-bold text-sm rounded-md h-10 p-1 px-3 flex items-center justify-center"
              onClick={() => {
                if (isLoggedIn) {
                  navigate('/interview');
                } else {
                  window.open(`${server}api/v1/auth/google`, '_self');
                }
              }}
            >
              Join the waitlist
            </button>
            <div>
              <div className="flex items-center justify-center h-10 w-10 rounded-md mx-2">
                <IoMenu
                  className="h-8 w-8 text-white"
                  onClick={openScrollMenu}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileHeader;
