import React from 'react';
import interview_chat from './../../Assets/interview_chat.png';

const SkeletonLoader = () => {
  return (
    <div className="flex items-center space-x-2">
      <div>
        <img src={interview_chat} alt="loading" className="h-[27px]" />
      </div>
      <div className="w-[65%] h-24  rounded-md p-2">
        <div className="flex flex-col space-y-3 animate-pulse">
          <div className="w-98 bg-gray-200 h-2 rounded-md"></div>
          <div className="w-full bg-gray-200 h-2 rounded-md"></div>
          <div className="w-1/2 bg-gray-200 h-2 rounded-md"></div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonLoader;
