import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../slices/userSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import interviewStartLogo from '../../Assets/Start Interview.png';
import interviewHistoryLogo from '../../Assets/Interview History.png';
import InterviewPanelOption from './InterviewPanelOption/InterviewPanelOption';
import InterviewMainPanel from './InterviewMainPanel/InterviewMainPanel';
import MyInterviewsScreen from './MyInterviewsScreen/MyInterviewsScreen';
import FeedbackScreen from './FeedbackScreen/FeedbackScreen';
import LogoAndName from '../../Assets/LogoAndName.png';

const initialState = {
  prevSelectedIndex: 0,
  optionsList: [
    {
      panelLabel: 'Interview',
      image: interviewStartLogo,
      isSelected: true,
    },
    {
      panelLabel: 'History',
      image: interviewHistoryLogo,
      isSelected: false,
    },
    {
      panelLabel: 'Feedback',
      image: interviewHistoryLogo,
      isSelected: false,
    },
  ],
};

const handleClick = (index, panelOptions, setPanelOptions) => {
  let newPanelOptions = { ...panelOptions };
  newPanelOptions.optionsList[newPanelOptions.prevSelectedIndex].isSelected =
    false;
  newPanelOptions.optionsList[index].isSelected = true;
  newPanelOptions.prevSelectedIndex = index;
  setPanelOptions(newPanelOptions);
};

export const getLeftPanel = (
  panelOptions,
  setPanelOptions,
  navigate,
  dispatch
) => {
  return (
    <div className="flex justify-between items-center m-5 px-10">
      <div className="flex items-center">
        <img
          src={LogoAndName}
          alt="Logo"
          className="cursor-pointer h-[30px]"
          onClick={() => {
            navigate('/');
          }}
        />
        <div className="flex ml-4 space-x-8 text-s">
          {panelOptions.optionsList.map((option, index) => (
            <div className="cursor-pointer" key={index}>
              <InterviewPanelOption
                key={index}
                isSelected={option.isSelected}
                panelOptionLabel={option.panelLabel}
                image={option.image}
                handleClick={handleClick}
                index={index}
                panelOptions={panelOptions}
                setPanelOptions={setPanelOptions}
              />
            </div>
          ))}
        </div>
      </div>

      <div>
        <div
          className="bg-[#5b5bf7] text-white p-3 rounded-xl text-sm cursor-pointer"
          onClick={() => {
            dispatch(logout());
            setTimeout(() => {
              navigate('/');
            }, 0);
          }}
        >
          Logout
        </div>
      </div>
    </div>
  );
};

const GetRightPanel = (selectedIndex) => {
  const user = useSelector((state) => state.user.user);
  const username = user ? user.username : 'Guest';

  return (
    <div className="px-20 pt-2">
      {selectedIndex === 0 && <InterviewMainPanel />}
      {selectedIndex === 1 && <MyInterviewsScreen />}
      {selectedIndex === 2 && <FeedbackScreen />}
    </div>
  );
};

function Interview() {
  const [panelOptions, setPanelOptions] = useState(initialState);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <>
      <div className=""></div>
      {getLeftPanel(panelOptions, setPanelOptions, navigate, dispatch)}
      <div className="border border-gray-300"></div>
      <div className="">
        {GetRightPanel(
          panelOptions.optionsList.findIndex(
            (option) => option.isSelected === true
          )
        )}
      </div>
    </>
  );
}

export default Interview;
