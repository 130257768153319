import React, { useEffect, useState } from 'react';
import { getRequest } from '../../../Util/apiHelper';
import { useSelector } from 'react-redux';
import InterviewHeading from '../../../components/InterviewHistory/InterviewHeading';
import InterviewTable from '../../../components/InterviewHistory/InterviewTable';

const fetchAllInterviews = async (setInterviews, token) => {
  const result = await getRequest('api/v1/myInterviews/all', token);
  const { interviews } = result.data;
  setInterviews(interviews);
  console.log('interviews');
};

const MyInterviewsScreen = (props) => {
  const [interviews, setInterviews] = useState([]);
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    if (interviews.length === 0) {
      fetchAllInterviews(setInterviews, user.token);
    } else {
    }
  }, [interviews.length, user.token]);
  useEffect(() => {
    console.log('interviews is', interviews);
  });

  return (
    <>
      <InterviewHeading
        style={{
          fontSize: '16px',
          paddingTop: '10px',
          paddingBottom: '10px',
          paddingLeft: '20px',
        }}
      />
      <InterviewTable />
    </>
  );
};

export default MyInterviewsScreen;
