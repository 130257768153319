import React from 'react';

const InterviewHeading = ({ style }) => {
  return (
    <div style={style}>
      <h1 className="text-black font-bold text-lg">My interviews</h1>
      <p className="text-gray-700 text-xs"> View all your interviews</p>
    </div>
  );
};

export default InterviewHeading;
