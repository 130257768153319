import { ProblemSolvesData } from '../../../Util/data-arr';

const Problem = () => {
  return (
    <div className="clip-path-custom2 bg-[#01011d] p-2 flex flex-col pt-60 items-center">
      {ProblemSolvesData.map((item, index) => (
        <div key={index} className="flex flex-col items-center mb-10">
          <img
            src={item.img}
            className="w-[350px] h-[250px] mb-4"
            alt={item.heading}
          />
          <div className="p-5 flex flex-col items-center text-center">
            <h1 className="text-[#5b5bf7] font-bold text-sm mb-2 inline">
              {' '}
              <span className="font-bold">|</span> {item.heading}
            </h1>
            <p className="text-white py-1 text-sm inline-block w-3/4">
              {item.para}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Problem;
