import React from 'react';
import styles from './PaymentTile.module.css';
import { useSelector } from 'react-redux';
import myBucket from '../../../../Util/aws-config';
import { postRequest } from '../../../../Util/apiHelper';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCouponInfoFromCode } from '../../../../Util/globals';

const getPricing = (priceForCurrentInterview, interviewPrice) => {
  if (priceForCurrentInterview === 0) {
    return <div className={styles.pricing}>Free</div>;
  } else {
    return <div className={styles.pricing}>$ {priceForCurrentInterview}</div>;
  }
};

const getPaymentButton = (
  priceForCurrentInterview,
  user,
  resumeFile,
  jdFile,
  jdString,
  setIsLoading,
  bucket,
  navigate,
  location,
  setInterviewId,
  setFirstQuestion,
  interviewType,
  couponCode
) => {
  const buttonText =
    priceForCurrentInterview === 0 ? 'Start Interview' : 'Make Payment';
  return (
    <div className={styles.paymentDiv}>
      <div
        className={styles.paymentButton}
        onClick={() =>
          onClick(
            user,
            resumeFile,
            jdFile,
            jdString,
            setIsLoading,
            bucket,
            navigate,
            location,
            setInterviewId,
            setFirstQuestion,
            interviewType,
            couponCode
          )
        }
      >
        {buttonText}
      </div>
    </div>
  );
};

const uploadFile = async (bucket, file, key, textInput) => {
  const params = {
    Body: file,
    Bucket: bucket,
    Key: key,
  };

  await new Promise((resolve, reject) => {
    myBucket.putObject(params).send((err, data) => {
      if (err) reject(err);
      else resolve(data);
    });
  });

  return params;
};

const getFolderName = (userName) => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  const hours = String(currentDate.getHours()).padStart(2, '0');
  const minutes = String(currentDate.getMinutes()).padStart(2, '0');
  const seconds = String(currentDate.getSeconds()).padStart(2, '0');

  const dateTimeString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return `${userName}_${dateTimeString}`;
};

const initializeBackendForInterview = async (
  bucket,
  folderName,
  token,
  isJdUploaded,
  interviewType
) => {
  // Send POST request to initialize endpoint
  return await postRequest(
    'api/v1/interview/initialize',
    {
      bucket,
      folderName,
      isJdUploaded,
      interviewType,
    },
    token,
    null,
    true,
    true
  );
};

const onClick = async (
  user,
  resumeFile,
  jdFile,
  jdString,
  setIsLoading,
  bucket,
  navigate,
  location,
  setInterviewId,
  setFirstQuestion,
  interviewType,
  couponCode
) => {
  if (user && resumeFile) {
    setIsLoading(true);
    let isJdUploaded = false;
    const folderName = getFolderName(user.id);
    await uploadFile(bucket, resumeFile, `${folderName}/Resume.pdf`, null);

    if (jdFile) {
      await uploadFile(bucket, jdFile, `${folderName}/JD.txt`);
      isJdUploaded = true;
    } else if (jdString && jdString.length > 0) {
      await uploadFile(
        bucket,
        new Blob([jdString], { type: 'text/plain' }),
        `${folderName}/JD.txt`
      );
      isJdUploaded = true;
    }

    if (!interviewType && couponCode) {
      const couponInfo = getCouponInfoFromCode(couponCode);
      interviewType = couponInfo.type;
      console.log('Fetched Interview Type: ', interviewType);
    }

    const response = await initializeBackendForInterview(
      bucket,
      folderName,
      user.token,
      isJdUploaded,
      interviewType
    );
    await setFirstQuestion({
      role: response.data.firstQuestion.role,
      content: response.data.firstQuestion.content,
    });
    await setInterviewId(response.data.id);
    await navigate(location.search.split('/')[0] + '/' + response.data.id, {
      replace: false,
    });
    setIsLoading(false);
  }
};
const PaymentTile = (props) => {
  const user = useSelector((state) => state.user.user);
  const bucket = 'deepvisionresumebucket';
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className={styles.paymentTile}>
      {getPricing(props.priceForCurrentInterview, props.interviewPrice)}
      {getPaymentButton(
        props.priceForCurrentInterview,
        user,
        props.resumeFile,
        props.jdFile,
        props.jdString,
        props.setIsLoading,
        bucket,
        navigate,
        location,
        props.setInterviewId,
        props.setFirstQuestion,
        props.interviewType,
        props.couponCode
      )}
    </div>
  );
};

export default PaymentTile;
