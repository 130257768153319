import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { server } from '../../Util/constants';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { FiPlus } from 'react-icons/fi';
import { FiMinus } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { getCurrentScreenSize } from '../../Util/Screen';
import MobileHeader from './mobile-view/MobileHeader';
import Footer from './mobile-view/Footer';
import { AiFillThunderbolt } from 'react-icons/ai';
import ManResumeLP from '../../Assets/ManResumeLP.png';
import ResumeFrame from '../../Assets/ResumeFrame.png';
import Arrow from '../../Assets/Arrow.png';
import LogoAndName from '../../Assets/LogoAndName.png';
import HurryUp from '../../Assets/HurryUp.png';
import insta from '../../Assets/insta.png';
import linkedin from '../../Assets/linkedin.png';
import How_it_works from '../../Assets/How_it_works.png';
import UpArrow from '../../Assets/UpArrow.png';
import DownArrow from '../../Assets/DownArrow.png';
import {
  BenefitP1,
  PracticeMockData,
  ProblemSolvesData,
  BenefitP12,
} from '../../Util/data-arr';
import TagLine from './mobile-view/TagLine';
import Benefits from './mobile-view/Benefits';
import Problem from './mobile-view/Problem';
import Card from './mobile-view/Card';

const LandingPage = () => {
  //************************************************************************************************************************************* */

  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [screenInfo, setScreenInfo] = useState({
    isMobileView: false,
    isLargeMobile: false,
    isIpad: false,
    isLargeView: false,
  });
  const benefitsRef = useRef(null);
  const howItWorksRef = useRef(null);
  const faqRef = useRef(null);
  useEffect(() => {
    const handleResize = () => setScreenInfo(getCurrentScreenSize());
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    handleScroll();
    handleResize();

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const { isMobileView, isLargeMobile, isLargeView, isIpad } = screenInfo;

  const scrollToSection = (sectionRef) => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.error('Ref is not assigned to any DOM element');
    }
  };

  const handleScroll = () => {
    setScrolled(window.scrollY > 0);
  };

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  //************************************************************************************************************************************* */

  const renderHeader = () => {
    const isLoggedIn = user ? true : false;
    if (isMobileView || isLargeMobile) {
      return <MobileHeader />;
    }

    if (scrolled) {
      return (
        <div className="fixed top-0 left-0 right-0 mx-7 text-black z-50">
          <div className="grid grid-cols-3 bg-white text-black p-2 mt-5 rounded-3xl mx-7">
            <div className="flex items-center pl-5">
              <img
                src={LogoAndName}
                alt="Logo"
                className="h-[34px]"
                onClick={() => window.location.reload()}
                style={{ cursor: 'pointer' }}
              />
            </div>
            <div className="flex gap-10 text-sm mt-2 justify-center">
              <p
                className="cursor-pointer"
                onClick={() => scrollToSection(benefitsRef)}
              >
                Benefits
              </p>
              <p
                className="cursor-pointer"
                onClick={() => scrollToSection(howItWorksRef)}
              >
                How it Works
              </p>
              <p
                className="cursor-pointer"
                onClick={() => scrollToSection(faqRef)}
              >
                FAQs
              </p>
            </div>
            <div className="flex items-center justify-end pr-5">
              <button
                className="font-medium bg-[#5b5bf7] text-white px-4 py-2 rounded cursor-pointer"
                onClick={() => {
                  if (isLoggedIn) {
                    navigate('/interview');
                  } else {
                    window.open(`${server}api/v1/auth/google`, '_self');
                  }
                }}
              >
                To the interview
              </button>
            </div>
          </div>
        </div>
      );
    }

    if (isIpad || isLargeView) {
      return (
        <div>
          <div className="grid grid-cols-3 bg-[#01011d] text-white  w-full pt-8">
            <div className="ml-10">
              <img
                src={LogoAndName}
                onClick={() => window.location.reload()}
                style={{ cursor: 'pointer' }}
              />
            </div>
            <div className="flex gap-20 text-sm mt-5">
              <p
                className="cursor-pointer"
                onClick={() => scrollToSection(benefitsRef)}
              >
                Benefits
              </p>
              <p
                className="inline whitespace-nowrap cursor-pointer"
                onClick={() => scrollToSection(howItWorksRef)}
              >
                How it Works
              </p>
              <p
                className="cursor-pointer"
                onClick={() => scrollToSection(faqRef)}
              >
                FAQs
              </p>
            </div>
            <div className="flex items-center justify-end mr-10">
              <div
                className="bg-[#5b5bf7] font-bold p-2 px-12 text-sm rounded-lg cursor-pointer"
                onClick={() => {
                  if (isLoggedIn) {
                    navigate('/interview');
                  } else {
                    window.open(`${server}api/v1/auth/google`, '_self');
                  }
                }}
              >
                {isLoggedIn ? 'To the interview' : 'Login'}
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const renderTagLine = () => {
    const isLoggedIn = user ? true : false;
    if (isMobileView || isLargeMobile) {
      return (
        <div className="clip-path-custom bg-[#01011d] w-full h-[670px] flex flex-col justify-center items-center">
          <div className="inline-flex items-center text-yellow-300 border border-yellow-300 rounded-3xl px-2 py-1 mt-10 bg-yellow-300 bg-opacity-20">
            <AiFillThunderbolt className="mr-1" />
            <p>Personalised with AI</p>
          </div>
          <div className="items-center text-center m-2">
            <h1 className="text-white font-bold text-3xl">
              Practice interviews
            </h1>
            <div className="flex items-center justify-center">
              <div className="flex flex-col items-end pr-5">
                <h1 className="text-[#5b5bf7] font-bold text-3xl mb-4">
                  Driven by your resume
                </h1>
                <img src={ResumeFrame} alt="Resume Frame" />
              </div>
            </div>
            <div className="flex justify-center items-center w-3/4 max-w-3xl mx-auto">
              <p className="text-white mt-5 text-center">
                Experience AI crafted real interview scenarios. Prepare for
                questions you’ll most likely face.
              </p>
            </div>

            <button
              className="text-white font-medium bg-[#5b5bf7] p-2 px-4 m-2 rounded-lg"
              onClick={() => {
                if (isLoggedIn) {
                  navigate('/interview');
                } else {
                  window.open(`${server}api/v1/auth/google`, '_self');
                }
              }}
            >
              To the interview
            </button>
            <p className="text-white m-1">50+ joined</p>
            <div className="flex justify-center mb-4">
              <img src={ManResumeLP} alt="Man with resume" />
            </div>
          </div>
        </div>
      );
    }

    if (isIpad || isLargeView) {
      return <TagLine />;
    }
  };

  const renderBenefits = () => {
    if (isMobileView || isLargeMobile) {
      return <Benefits />;
    }

    if (isIpad || isLargeView) {
      return (
        <div className="bg-[#E7E7FE] -mt-[100px]">
          <h1 className="text-black font-bold text-3xl pt-40 text-center md:text-4xl">
            Empower your learning
          </h1>
          <div className="text-center">
            <h1 className="text-black font-bold text-3xl md:text-4xl mb-4">
              with our <span className="text-[#5b5bf7]">unique benefits</span>
            </h1>
            <div className="ml-40">
              <img
                src={ResumeFrame}
                className="mx-auto"
                alt="Unique Benefits"
              />
            </div>
          </div>
          <div className="flex gap-3 p-60 pb-0 -mt-[200px] justify-center">
            {BenefitP1.map((item) => (
              <>
                <div
                  key={item.id}
                  className="bg-white flex flex-col items-center p-4 rounded-3xl w-[300px] h-[250px]"
                >
                  <img
                    src={item.img}
                    alt={item.headings}
                    className="mb-2 mt-10 h-[60px]"
                  />
                  <h1 className="text-md font-extrabold mb-2">
                    {item.headings}
                  </h1>
                  <p className="text-center text-xs font-extralight pb-5">
                    {item.para}
                  </p>
                </div>
              </>
            ))}
          </div>
          <div className="flex gap-3 p-60 pt-3 pb-[100px] justify-center ">
            {BenefitP12.map((item) => (
              <>
                <div
                  key={item.id}
                  className="bg-white flex flex-col items-center p-4 rounded-3xl w-[300px] h-[250px]"
                >
                  <img
                    src={item.img}
                    alt={item.headings}
                    className="mb-2 mt-10 h-[60px]"
                  />
                  <h1 className="text- font-extrabold ">{item.headings}</h1>
                  <p className="text-center text-xs font-extralight ">
                    {item.para}
                  </p>
                </div>
              </>
            ))}
          </div>
        </div>
      );
    }
  };

  const renderCard = () => {
    if (isMobileView || isLargeMobile || isIpad) {
      return <Card />;
    }

    if (isLargeView) {
      return (
        <div className="pt-8 px-[120px]">
          <div className="mt-5">
            <h1 className="text-black font-bold text-3xl text-center md:text-4xl">
              Practice mock interview
            </h1>
            <div className="flex flex-col items-center text-center">
              <h1 className="text-black font-bold text-3xl md:text-4xl mb-4">
                in <span className="text-[#5b5bf7]">4 easy steps</span>
              </h1>
              <img src={ResumeFrame} className="w-auto" alt="Resume Frame" />
            </div>
          </div>
          <div className="flex float-left mt-20 ml-5">
            <img src={Arrow} className="h-[60px]" />
            <img src={How_it_works} className="h-[60px]" />
          </div>
          <div className=" relative flex flex-wrap w-full px-5 pt-5 my-40 lg:grid lg:grid-cols-4 lg:gap-4 gap-6">
            {PracticeMockData.map((item, index) => (
              <div
                key={index}
                className="relative border-2 border-gray-200 rounded-xl p-5 m-2 bg-white flex flex-col items-start text-left" // Changed to `items-start` and `text-left`
              >
                {index === 2 && (
                  <>
                    <img
                      src={UpArrow}
                      className="absolute -top-[20px] -left-[4px] transform -translate-x-1/2 -translate-y-1/2 z-10"
                    />
                  </>
                )}
                <div className="text-[#5b5bf7] font-extrabold bg-[#E7E7FE] w-[36px] h-[36px] rounded-full flex items-center justify-center m-4">
                  {item.id}
                </div>
                <h1 className="font-bold text-sm ml-4 inline">
                  {item.heading}
                </h1>{' '}
                <p className="font-extralight text-xs ml-4">{item.para}</p>{' '}
                {index % 2 !== 0 && (
                  <img
                    src={DownArrow}
                    className="absolute bottom-[-20px] mr-[135px] right-1/2 transform translate-x-1/2 translate-y-1/2 z-10 "
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      );
    }
  };

  const renderProblemSolves = () => {
    if (isMobileView || isLargeMobile || isIpad) {
      return <Problem />;
    }
    return (
      <div className="clip-path-custom2 bg-[#01011d] p-40 flex flex-col pt-60">
        {ProblemSolvesData.map((item, index) => (
          <div
            key={index}
            className={`flex mb-4 m-10 ${
              index % 2 === 1 ? 'flex-row' : 'flex-row-reverse'
            }`}
          >
            <div className="p-10 pt-20 flex-1 ">
              <h1 className="text-[#5b5bf7] font-bold text-xl">
                {' '}
                <span className="font-bold">|</span> {item.heading}
              </h1>
              <p className="text-white py-1">{item.para}</p>
            </div>
            <div className="mr-4 flex-1">
              <img
                src={item.img}
                alt={item.heading}
                className="w-[400px] h-auto"
              />
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderFAQ = () => {
    const renderFAQTab = (question, answer, panel) => {
      return (
        <div className="bg-gray-200 rounded-lg mb-5">
          <Accordion
            expanded={expanded === panel}
            onChange={handleChangeAccordion(panel)}
            className="bg-gray-100 p-0"
          >
            <AccordionDetails className="bg-gray-100">
              <AccordionSummary
                expandIcon={
                  expanded === panel ? (
                    <FiMinus className="font-bold" />
                  ) : (
                    <FiPlus />
                  )
                }
                aria-controls={`${panel}-content`}
                id={`${panel}-header`}
                className="bg-gray-200 p-0"
              >
                <div className="font-bold">{question}</div>
              </AccordionSummary>
            </AccordionDetails>
            <AccordionDetails className="bg-gray-100">
              <div className="font-extralight p-4 pt-0">{answer}</div>
            </AccordionDetails>
          </Accordion>
        </div>
      );
    };

    return (
      <div className=" px-3 w-full -mt-20 md:flex md:flex-col md:items-center md:-mt-20">
        <div className="text-center font-extrabold mb-5 md:text-3xl">FAQs</div>
        <div className="flex flex-col items-center md:w-[500px]">
          {renderFAQTab(
            'What is an AI-based mock interview?',
            'Our platform uses artificial intelligence to simulate real-life job interviews based on your resume and the specific job description you provide. This allows for a highly personalized practice experience.',
            'panel1'
          )}
          {renderFAQTab(
            'How does the AI generate interview questions?',
            'The AI generates interview questions by analyzing the job description and your resume, tailoring questions to match the skills and experiences required for the position you are applying for.',
            'panel2'
          )}
          {renderFAQTab(
            'What kind of feedback can I expect after my mock interview?',
            'You will receive detailed feedback on your performance, including strengths, areas for improvement, and tips on how to enhance your interview skills.',
            'panel3'
          )}
          {renderFAQTab(
            'How long does each mock interview session last?',
            'Each mock interview session typically lasts between 30 to 45 minutes, depending on the complexity of the questions and the depth of your responses.',
            'panel4'
          )}
        </div>
      </div>
    );
  };

  const renderJoinWaitList = () => {
    const isLoggedIn = user ? true : false;
    return (
      <div className="mt-10 flex items-center justify-center  md:mt-20">
        <div className="bg-[#01011d] font-bold text-3xl text-center items-center  justify-center py-20 m-3 rounded-xl w-3/4 md: w-[800px]">
          <h1 className="text-white text-2xl md:text-4xl">
            Ready to practice interviews
          </h1>
          <div className="flex flex-col items-center text-center">
            <h1 className="text-[#5b5bf7] font-bold text-2xl md:text-4xl">
              driven by your resume
            </h1>
            <img
              src={ResumeFrame}
              className=" ml-20 md:mt-1 md:ml-40"
              alt="Resume Frame"
            />
          </div>

          <div className="flex mt-5">
            <img src={Arrow} className="ml-10" />
            <img src={HurryUp} className="-ml-1 h-[40px]" />
          </div>
          <button
            className="text-white p-6 bg-[#5b5bf7] mt-5 rounded-lg text-2xl md: w-3/4 md:p-10"
            onClick={() => {
              if (isLoggedIn) {
                navigate('/interview');
              } else {
                window.open(`${server}api/v1/auth/google`, '_self');
              }
            }}
          >
            To the interview
          </button>
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    if (isMobileView) {
      return <Footer />;
    }

    return (
      <div className="bg-[#e7e7fe] grid grid-cols-3 p-5 pt-10 mt-20">
        <div className="">
          <img src={LogoAndName} alt="Logo" className="h-[34px]" />
          <div className="flex gap-2 p-5">
            <img src={insta} alt="Logo" className="h-[12px]" />
            <img src={linkedin} alt="Logo" className="h-[12px]" />
          </div>
        </div>
        <div className="">
          <h1 className="font-bold py-1">Company</h1>
          <h1 className="py-1">Privacy Policy</h1>
          <h1 className="underline py-1">Terms of Use</h1>
        </div>
        <div className="">
          <div>
            1207 /343 & 1207 /1/343/1, 9th Main, 7th Sector, HSR Layout,
            Bangalore South, Bangalore- 560102, Karnataka
          </div>
          {/* <div>CIN: U62091KA2023PTC180209</div> */}
        </div>
      </div>
    );
  };

  //************************************************************************************************************************************* */

  return (
    <div>
      <div className="bg-[#01011d]">
        {renderHeader()}
        {renderTagLine()}
      </div>
      <div ref={benefitsRef} id="benefits">
        {renderBenefits()}
      </div>
      <div ref={howItWorksRef} id="how-it-works">
        {renderCard()}
      </div>

      {renderProblemSolves()}
      <div ref={faqRef} id="faqs" className="mt-[160px]">
        {renderFAQ()}
      </div>
      {renderJoinWaitList()}
      {renderFooter()}
    </div>
  );
};

export default LandingPage;
