import React from 'react';

const InterviewNotFound = () => {
  return (
    <>
      <div className="flex justify-center text-gray-600">
        No interviews found. Please go to start interviews tab and begin your
        interviews.
      </div>
    </>
  );
};

export default InterviewNotFound;
