import ResumeFrame from '../../../Assets/ResumeFrame.png';
import { BenefitP1 } from '../../../Util/data-arr';
import { BenefitP12 } from '../../../Util/data-arr';
const Benefits = () => {
  return (
    <div className="bg-[#E7E7FE] -mt-20 pt-0 pb-10 ">
      <h1 className="text-black font-bold text-3xl pt-40 text-center">
        Empower your learning
      </h1>
      <div className="text-center">
        <h1 className="text-black font-bold text-3xl md:text-4xl mb-4">
          with our <span className="text-[#5b5bf7]">unique benefits</span>
        </h1>
        <div className="ml-40">
          <img
            src={ResumeFrame}
            className="mx-auto mb-4"
            alt="Unique Benefits"
          />
        </div>
      </div>

      <div className="flex flex-wrap w-full pt-5  justify-center ">
        {BenefitP1.map((item, index) => (
          <div
            key={item.id}
            q
            className="bg-white flex flex-col items-center p-2 pb-8 rounded-xl m-4 sm:w-1/2"
          >
            <img src={item.img} alt={item.headings} className="mb-4 mt-4 p-5" />
            <h1 className="text-sm font-bold mb-2">{item.headings}</h1>
            <p className="text-sm text-center font-extralight">{item.para}</p>
          </div>
        ))}
      </div>
      <div className="flex flex-wrap w-full pt-2 justify-center ">
        {BenefitP12.map((item, index) => (
          <div
            key={item.id}
            q
            className="bg-white  flex flex-col items-center p-2 pb-8 rounded-xl m-4 sm:w-1/2"
          >
            <img src={item.img} alt={item.headings} className="mb-4 mt-4 p-5" />
            <h1 className="text-sm font-bold mb-2">{item.headings}</h1>
            <p className="text-sm text-center font-extralight">{item.para}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Benefits;
