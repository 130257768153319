import React, { useState } from 'react';
import film from '../../../Assets/film.png';
import InterviewTypeTile from './InterviewTypeTile/InterviewTypeTile';
import { useNavigate } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { toast } from 'react-toastify';
import { checkCouponCode } from '../../../Util/globals';
import Name from '../../../components/Name';
export let interviewOptionsUIHelper = [
  [
    { type: 'Computer Vision', image: film },
    { type: 'Data Science', image: film },
    { type: 'Machine Learning', image: film },
    { type: 'Product Manager', image: film },
  ],
  [
    { type: 'Robotics', image: film },
    { type: 'Software Development', image: film },
    { type: 'User Experience (UX)', image: film },
    { type: 'Other', image: film },
  ],
];

const GetInterviewOptionsDiv = (props) => {
  const { couponCode, setCouponCode, interviewType, setInterviewType } = props;
  return (
    <>
      <div className="w-full select-none">
        {interviewOptionsUIHelper.map((optionRow, rowIndex) => (
          <div key={rowIndex} className="flex w-full gap-4 m-10 ml-0">
            {optionRow.map((row) => (
              <div key={row.type} className="flex-1">
                <InterviewTypeTile
                  type={row.type}
                  image={row.image}
                  setInterviewType={setInterviewType}
                  isSelected={row.type === interviewType}
                  disabled={!!couponCode}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
      <div>
        <input
          value={couponCode}
          className="p-2 pr-20 border shadow-sm rounded-sm select-none"
          placeholder="Enter interview code"
          onChange={(e) => setCouponCode(e.target.value)}
          disabled={!!interviewType}
        />
      </div>
    </>
  );
};

const getHeader = () => {
  return (
    <div className="flex flex-col justify-start mt-2">
      <Name styles={{ fontSize: '18px' }} />
      <p className="font-bold text-xl mt-5 ">
        Select the type of domain you're targeting
      </p>
    </div>
  );
};

const GetFooter = (props, navigate) => {
  const {
    couponCode,
    interviewType,
    interviewScreenStages,
    panelType,
    setPanelType,
  } = props;

  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    if (!interviewType && !couponCode) {
      return;
    }

    const isValidCoupon = checkCouponCode(couponCode);

    const applyLoading = (callback) => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        callback();
      }, 2000);
    };

    if (interviewType) {
      onNextClick(
        interviewScreenStages,
        setPanelType,
        panelType,
        navigate,
        interviewType,
        couponCode
      );
      return;
    }

    if (isValidCoupon) {
      applyLoading(() =>
        onNextClick(
          interviewScreenStages,
          setPanelType,
          panelType,
          navigate,
          interviewType,
          couponCode
        )
      );
    } else {
      applyLoading(() => toast.error('Invalid coupon'));
    }
  };

  return (
    <div className="flex justify-center mb-[200px]">
      <div
        className={`text-white  font-bold flex items-center justify-center rounded-lg ${
          !interviewType && !couponCode
            ? 'bg-[#5b5bf7] bg-opacity-50 cursor-not-allowed'
            : 'bg-[#5b5bf7]'
        }`}
        onClick={handleClick} // Keep this onClick handler
      >
        <div className="cursor-pointer px-[100px] py-2 rounded-lg flex items-center justify-center">
          <div className="pr-2 select-none"> Next</div>

          {loading ? (
            <ClipLoader
              color="#FFFFFF"
              loading={loading}
              size={18}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

const onNextClick = (
  stages,
  setPanelType,
  panelType,
  navigate,
  interviewType,
  couponCode = null
) => {
  for (let i = 0; i < stages.length; i++) {
    if (stages[i] === panelType) {
      setPanelType(stages[i + 1]);
      break;
    }
  }

  if (couponCode) {
    const isValidCoupon = checkCouponCode(couponCode);
    if (isValidCoupon) {
      const queryParams = new URLSearchParams({
        interviewType: interviewType || null,
        couponCode: couponCode,
      });
      navigate(`/interview?${queryParams.toString()}`, { replace: false });
    } else {
      console.error('The coupon code is invalid');
    }
  } else {
    const queryParams = new URLSearchParams({
      interviewType: interviewType || null,
      couponCode: couponCode || null,
    });

    navigate(`/interview?${queryParams.toString()}`, { replace: false });
  }
};

const InterviewTypeScreen = (props) => {
  const navigate = useNavigate();

  return (
    <div className="">
      {getHeader()}
      <GetInterviewOptionsDiv {...props} />
      {GetFooter({ ...props }, navigate)}
    </div>
  );
};

export default InterviewTypeScreen;
