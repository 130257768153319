import ManResumeLP from '../../../Assets/ManResumeLP.png';
import ResumeFrame from '../../../Assets/ResumeFrame.png';
import hero_section_large from '../../../Assets/hero_section_large.png';
import { AiFillThunderbolt } from 'react-icons/ai';

const TagLine = () => {
  return (
    <>
      <div className=" clip-path-custom3 bg-[#01011d] w-full  flex flex-col justify-center items-center">
        <div className="grid grid-cols-[60%_auto] p-20">
          <div>
            <div className="inline-flex items-center text-yellow-300 border border-yellow-300 rounded-3xl px-2 py-1 mt-10 bg-yellow-300 bg-opacity-20 text-sm">
              <AiFillThunderbolt className="mr-1" />
              <p>Personalised with AI</p>
            </div>
            <h1 className="text-white font-bold text-4xl mt-5">
              Practice interviews
            </h1>

            <div className="flex flex-col items-start">
              <h1 className="text-[#5b5bf7] font-bold text-4xl ">
                driven by your resume
              </h1>
              <img
                src={ResumeFrame}
                className="ml-[200px]"
                alt="Resume Frame"
              />
            </div>

            <p className="text-white mt-5 text-xl w-4/5">
              Experience AI crafted real interview scenarios. Prepare for
              questions you’ll most likely face.
            </p>
            <div className="flex mt-4 text-xl">
              <button
                className="text-white font-medium text-sm bg-[#5b5bf7] p-2 px-4 ml-0 m-2 rounded-lg"
                onClick={() => {
                  window.open('https://forms.gle/nKsnp33GN2wgzm6x5', '_blank');
                }}
              >
                Join the waitlist
              </button>
              <p className="text-white m-1 mt-4 text-sm">50+ joined</p>
            </div>
          </div>
          <div>
            <img
              src={hero_section_large.replace('.jpg', '.webp')}
              alt="Man with resume"
              className="h-[450px] mt-1"
              quality={100}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TagLine;
