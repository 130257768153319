import React from 'react';
import { PracticeMockData } from '../../../Util/data-arr';
import Arrow from '../../../Assets/Arrow.png';
import How_it_works from '../../../Assets/How_it_works.png';
import ResumeFrame from '../../../Assets/ResumeFrame.png';

const Card = () => {
  return (
    <div>
      <div className="pt-8">
        <div className="mt-5">
          <h1 className="text-black font-bold text-4xl text-center md:text-4xl">
            Practice mock interview
          </h1>
          <div className="flex flex-col items-center text-center">
            <h1 className="text-black font-bold text-4xl md:text-4xl mb-4">
              in <span className="text-[#5b5bf7]">4 easy steps</span>
            </h1>
            <img src={ResumeFrame} className="w-auto" alt="Resume Frame" />
          </div>
        </div>
        <div className="flex float-left mt-20 ml-20">
          <img src={Arrow} className="h-[60px]" />
          <img src={How_it_works} className="h-[60px]" />
        </div>
        <div className="flex flex-col w-full pt-5 my-40 -mt-60 items-center justify-center">
          {PracticeMockData.map((item, index) => (
            <div key={index} className="mb-2 md:w-1/2">
              <div className="border-4 border-gray-200 rounded-xl p-8 m-5">
                <div className="text-[#5b5bf7] font-extrabold bg-[#E7E7FE] w-[36px] h-[36px] rounded-full items-center text-center m-4 mt-2 pt-1">
                  {item.id}
                </div>
                <h1 className="font-bold">{item.heading}</h1>
                <p className="font-extralight ">{item.para}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Card;
