import React, { useState } from 'react';
import InterviewTypeScreen from '../InterviewTypeScreen/InterviewTypeScreen';
import styles from './InterviewMainPanel.module.css';
import FileUploadScreen from '../FileUploadScreen/FileUploadScreen';
import { useLocation } from 'react-router-dom';
import Chat from '../../Chat/Chat';

import PaymentScreen from '../PaymentScreen/PaymentScreen';
import { useDispatch, useSelector } from 'react-redux';
import Loaders from '../../../components/misc/Loaders';
const getInterviewPanelScreen = (
  panelType,
  setInterviewType,
  interviewType,
  setPanelType,
  location,
  setFirstQuestion,
  firstQuestion,
  interviewId,
  setInterviewId,
  setIsLoading,
  priceForCurrentInterview,
  setPriceForCurrentInterview,
  interviewPrice,
  setInterviewPrice,
  resumeFile,
  setResumeFile,
  jdFile,
  setJdFile,
  jdString,
  setJdString,
  couponCode,
  setCouponCode
) => {
  if (location.search === '') {
    return (
      <InterviewTypeScreen
        setInterviewType={setInterviewType}
        interviewType={interviewType}
        couponCode={couponCode}
        setCouponCode={setCouponCode}
        interviewScreenStages={interviewScreenStages}
        setPanelType={setPanelType}
        panelType={panelType}
      />
    );
  } else {
    const urlParams = location.search.split('/');
    if (urlParams.length === 1) {
      return (
        <FileUploadScreen
          setFirstQuestion={setFirstQuestion}
          setInterviewId={setInterviewId}
          setIsLoading={setIsLoading}
          setPriceForCurrentInterview={setPriceForCurrentInterview}
          setInterviewPrice={setInterviewPrice}
          setResumeFile={setResumeFile}
          jdFile={jdFile}
          setJdFile={setJdFile}
          jdString={jdString}
          setJdString={setJdString}
          resumeFile={resumeFile}
          couponCode={couponCode}
        />
      );
    } else {
      if (urlParams[1] === 'payment') {
        return (
          <PaymentScreen
            priceForCurrentInterview={priceForCurrentInterview}
            interviewPrice={interviewPrice}
            jdFile={jdFile}
            jdString={jdString}
            resumeFile={resumeFile}
            setIsLoading={setIsLoading}
            setFirstQuestion={setFirstQuestion}
            setInterviewId={setInterviewId}
            interviewType={interviewType}
            couponCode={couponCode}
          />
        );
      } else {
        if (!interviewId) {
          setInterviewId(urlParams[1]);
        }
        return <Chat id={interviewId} firstQuestion={firstQuestion} />;
      }
    }
  }
};

const interviewScreenStages = [
  'InterviewOptionsScreen',
  'FileUploadScreen',
  'ChatScreen',
];
const InterviewMainPanel = () => {
  const [panelType, setPanelType] = useState('InterviewOptionsScreen');
  const [interviewType, setInterviewType] = useState(null);
  const location = useLocation();
  const [firstQuestion, setFirstQuestion] = useState(null);
  const [interviewId, setInterviewId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [priceForCurrentInterview, setPriceForCurrentInterview] =
    useState(null);
  const [interviewPrice, setInterviewPrice] = useState(null);
  const [resumeFile, setResumeFile] = useState(null);
  const [jdFile, setJdFile] = useState(null);
  const [jdString, setJdString] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const user = useSelector((state) => state.user.user);
  const username = user ? user.username : 'Guest';
  return (
    <div className={styles.mainDiv}>
      {isLoading ? (
        <Loaders />
      ) : (
        getInterviewPanelScreen(
          panelType,
          setInterviewType,
          interviewType,
          setPanelType,
          location,
          setFirstQuestion,
          firstQuestion,
          interviewId,
          setInterviewId,
          setIsLoading,
          priceForCurrentInterview,
          setPriceForCurrentInterview,
          interviewPrice,
          setInterviewPrice,
          resumeFile,
          setResumeFile,
          jdFile,
          setJdFile,
          jdString,
          setJdString,
          couponCode,
          setCouponCode
        )
      )}
    </div>
  );
};

export default InterviewMainPanel;
