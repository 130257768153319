import myBucket from './aws-config';
import { saveAs } from 'file-saver';

const downloadMetadataFromS3 = (
  key,
  bucket,
  filename,
  extension,
  alertMessage
) => {
  const params = {
    Bucket: bucket,
    Key: `${key}/${filename}`,
  };
  myBucket.getObject(params, (err, data) => {
    if (err) {
      console.error(`Error downloading file: ${err}`);
      alert(alertMessage);
    } else {
      const blob = new Blob([data.Body], { type: extension });
      saveAs(blob, filename);
    }
  });
};

export { downloadMetadataFromS3 };
