import React from 'react';
import styles from './PaymentScreen.module.css';
import PaymentTile from './PaymentTile/PaymentTile';
const getHeader = () => {
  return <div className={styles.headerDiv}>Make Your Payment</div>;
};
const PaymentScreen = (props) => {
  return (
    <div className={styles.mainDiv}>
      {getHeader()}
      <PaymentTile
        priceForCurrentInterview={props.priceForCurrentInterview}
        interviewPrice={props.interviewPrice}
        resumeFile={props.resumeFile}
        jdFile={props.jdFile}
        jdString={props.jdString}
        setIsLoading={props.setIsLoading}
        setInterviewId={props.setInterviewId}
        setFirstQuestion={props.setFirstQuestion}
        interviewType={props.interviewType}
        couponCode={props.couponCode}
      />
    </div>
  );
};

export default PaymentScreen;
