import React, { useEffect, useState } from 'react';
import styles from './FileUploadScreen.module.css';
import TextField from '@mui/material/TextField';
import FileUploader from './FileUploader/FileUploader';
import Header from '../../../Assets/HeaderMain.png';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getRequest } from '../../../Util/apiHelper';
import ClipLoader from 'react-spinners/ClipLoader';
import { getCouponInfoFromCode } from '../../../Util/globals';
import Name from '../../../components/Name';
const fetchInterviewPriceForUser = async (token) => {
  // Send POST request to initialize endpoint
  return await getRequest('api/v1/payment/price', token, null, true, true);
};

const getJdSection = (jdFile, setJdFile, jdString, setJdString) => {
  return (
    <div className="w-full">
      <div className="">
        <p className={styles.formLabel}>Job Description Upload: </p>
        <FileUploader
          footerText="only supports .txt file"
          fileType=".txt"
          setFile={setJdFile}
          fileUploaded={jdFile}
          isDisabled={jdString && jdString.length > 0}
        />
      </div>
    </div>
  );
};

const getJdSectionTxt = (jdFile, jdString, setJdString) => {
  return (
    <div className="text-shadow-blue">
      <TextField
        fullWidth
        className="text-shadow-blue"
        id="outlined-multiline-static"
        label=""
        multiline
        rows={6}
        variant="outlined"
        placeholder=""
        value={jdString}
        sx={{
          backgroundColor: 'white',
        }}
        onChange={(e) => setJdString(e.target.value)}
        disabled={!!jdFile}
      />
    </div>
  );
};

const getResumeSection = (resumeFile, setResumeFile) => {
  return (
    <div className="flex flex-col">
      <Name styles={{ marginTop: '20px' }} />
      <p className="font-bold py-5 text-lg">
        Attach resume and job description:
      </p>
      <div className="flex justify-between mb-4">
        <p className="text-sm font-bold -mt-2">Upload your resume*</p>
        <p className="text-sm text-gray-500">*Mandatory</p>
      </div>
      <FileUploader
        footerText="only .pdf files can be uploaded"
        fileType=".pdf"
        setFile={setResumeFile}
        fileUploaded={resumeFile}
      />
    </div>
  );
};

export const GetForm = (
  jdFile,
  setJdFile,
  resumeFile,
  setResumeFile,
  jdString,
  setJdString,
  couponCode
) => {
  const [isUploadSelected, setIsUploadSelected] = useState(false);
  const [isEnterSelected, setIsEnterSelected] = useState(false);
  console.log('The coupon code is', couponCode);
  const handleUploadChange = (e) => {
    setIsUploadSelected(e.target.checked);
    setIsEnterSelected(false);
  };

  const handleEnterChange = (e) => {
    setIsEnterSelected(e.target.checked);
    setIsUploadSelected(false);
  };

  return (
    <div className="space-y-4">
      <div>{getResumeSection(resumeFile, setResumeFile)}</div>
      {couponCode === '' ? (
        <>
          {' '}
          <h1 className="text-xs py-0 pt-0">Job description*</h1>
          <div className="flex flex-col space-y-2">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                className="checkbox"
                checked={isUploadSelected}
                onChange={handleUploadChange}
              />
              <span className="text-xs">Upload</span>
            </label>
            {isUploadSelected &&
              getJdSection(jdFile, setJdFile, jdString, setJdString)}
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                className="form-checkbox"
                checked={isEnterSelected}
                onChange={handleEnterChange}
              />
              <span className="text-xs">Enter</span>
            </label>
            {isEnterSelected && getJdSectionTxt(jdFile, jdString, setJdString)}
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

const onNextClick = async (
  user,
  resumeFile,
  jdFile,
  bucket,
  props,
  navigate,
  location,
  jdString,
  setInterviewPrice,
  setPriceForCurrentInterview
) => {
  const interviewPriceResponse = await fetchInterviewPriceForUser(user.token);
  setInterviewPrice(interviewPriceResponse.data.interviewPrice);
  setPriceForCurrentInterview(
    interviewPriceResponse.data.interviewPriceForCurrentInterview
  );

  await navigate(location.search + '/payment', {
    replace: false,
  });
};

// const getFooter = (
//   resumeFile,
//   jdFile,
//   user,
//   bucket,
//   navigate,
//   location,
//   setInterviewPrice,
//   setPriceForCurrentInterview,
//   onNextClick,
//   jdString,
//   setJdString
// ) => {
//   return (
//     <div className="flex items-center justify-center mt-5 mb-[150px] select-none">
//       <div
//         className={`text-white  font-medium p-3 px-14 cursor-pointer rounded-xl ${!resumeFile ? ' bg-[#5b5bf7] bg-opacity-80' : 'bg-[#5b5bf7]'}`}
//         onClick={() => {
//           if (!resumeFile) {
//             return;
//           }
//           onNextClick(
//             user,
//             resumeFile,
//             jdFile,
//             bucket,
//             null,
//             navigate,
//             location,
//             jdString,
//             setInterviewPrice,
//             setPriceForCurrentInterview
//           );
//         }}
//       >
//         Start the interview
//       </div>
//     </div>
//   );
// };

const GetFooter = (
  resumeFile,
  jdFile,
  user,
  bucket,
  navigate,
  location,
  setInterviewPrice,
  setPriceForCurrentInterview,
  onNextClick,
  jdString,
  setJdString
) => {
  const [loading, setLoading] = useState(false); // Add loading state

  return (
    <div className="flex items-center justify-center mt-5 mb-[150px] select-none">
      <div
        className={`text-white font-medium p-3 px-14 cursor-pointer rounded-xl flex items-center justify-center ${
          !resumeFile ? ' bg-[#5b5bf7] bg-opacity-80' : 'bg-[#5b5bf7]'
        }`}
        onClick={() => {
          if (!resumeFile) {
            return;
          }
          setLoading(true);
          onNextClick(
            user,
            resumeFile,
            jdFile,
            bucket,
            null,
            navigate,
            location,
            jdString,
            setInterviewPrice,
            setPriceForCurrentInterview
          ).finally(() => {
            setLoading(false);
          });
        }}
      >
        <h1>Start the interview</h1>
        {loading ? (
          <div className="flex px-3">
            <ClipLoader color="#ffffff" size={24} />
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

const FileUploadScreen = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state.user.user);
  const bucket = 'deepvisionresumebucket';
  return (
    <div className="">
      <div className="">
        {GetForm(
          props.jdFile,
          props.setJdFile,
          props.resumeFile,
          props.setResumeFile,
          props.jdString,
          props.setJdString,
          props.couponCode
        )}
      </div>
      {GetFooter(
        props.resumeFile,
        props.jdFile,
        user,
        bucket,
        navigate,
        location,
        props.setInterviewPrice,
        props.setPriceForCurrentInterview,
        onNextClick,
        props.jdString,
        props.setJdString
      )}
    </div>
  );
};

export default FileUploadScreen;
