import { getRequest, postRequest } from '../../Util/apiHelper';

export const fetchChatsForInterview = async (
  setMessages,
  token,
  interviewId,
  setChatsLoaded
) => {
  const result = await getRequest('api/v1/interview/' + interviewId, token);
  const messages = result.data.chats;
  const lastAudio = result.data.audio;
  console.log(lastAudio);
  setMessages(messages);
  setChatsLoaded(true);
};

export const endInterview = async (
  interviewId,
  user,
  audioSourceRef,
  navigate,
  stopRecording
) => {
  try {
    await postRequest(
      'api/v1/interview/end',
      {
        interviewId: interviewId,
      },
      user.token
    );
    if (audioSourceRef.current) {
      audioSourceRef.current.stop();
    }
    await stopRecording();
    navigate('/interview');
  } catch (error) {
    console.error('Error ending interview:', error);
  }
};

export const getMessagesForBatching = (sanitizedResponseText) => {
  const messagesSplitArray = sanitizedResponseText.split('.').slice(2);
  const tempMessagesForBatching = [];
  for (let i = 0; i < messagesSplitArray.length; i++) {
    if (messagesSplitArray[i] !== '') {
      tempMessagesForBatching.push(messagesSplitArray[i]);
    }
  }
  return tempMessagesForBatching;
};
