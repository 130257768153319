import { Routes, Route, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import Chat from './Pages/Chat/Chat';
import LandingPage from './Pages/LandingPage/LandingPage';
import { fetchUser } from './slices/userSlice';
import { ToastContainer, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Interview from './Pages/Interview/Interview';

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    if (!user) {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('token');
      if (token) {
        dispatch(fetchUser(token));
      }
    } else {
    }
  }, []);

  const userNotLoggedIn = () => {
    return (
      <div className="">
        <Routes>
          <Route path="*" element={<LandingPage />} />
        </Routes>
      </div>
    );
  };

  const userLoggedIn = () => {
    return (
      <div className="">
        <Routes>
          <Route path="/chat" element={<Chat />} />
          <Route path="/interview" element={<Interview />} />
          <Route exact path="/" element={<LandingPage />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    );
  };

  return (
    <div>
      {user && userLoggedIn()}
      {!user && userNotLoggedIn()}
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      />
    </div>
  );
}

export default App;
