import { useState } from 'react';
import styles from './FeedbackScreen.module.css';
import { TextField, Button, Box } from '@mui/material';
import { postRequest } from '../../../Util/apiHelper';
import ClipLoader from 'react-spinners/ClipLoader';

const FeedbackScreen = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [isSending, setIsSending] = useState(false);
  const validateForm = () => {
    if (!formData.name) {
      alert('Please enter name.');
      return false;
    }
    if (!formData.email) {
      alert('Please enter email.');
      return false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      alert('Invalid email');
      return false;
    }
    if (!formData.message) {
      alert('Please enter message.');
      return false;
    }
    return true;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSending(true);
    const { name, email, message } = formData;
    if (validateForm()) {
      await postRequest(`api/v1/feedback`, { name, email, message });
      setIsSending(false);
      alert('Your message has been sent.');
      setFormData({
        name: '',
        email: '',
        message: '',
      });
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const renderContactForm = () => {
    return (
      <div className={styles.contactFormRoot}>
        <div className={styles.contactFormHeading}>Have any concerns?</div>
        <Box
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            width: '360px',
            marginTop: '16px',
            backgroundColor: '#f5fdff',
            borderRadius: 2,
          }}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <TextField
            name="name"
            label="Name"
            variant="filled"
            fullWidth
            value={formData.name}
            onChange={handleInputChange}
            style={{ color: '#333333' }}
          />
          <TextField
            name="email"
            label="Email"
            variant="filled"
            fullWidth
            value={formData.email}
            onChange={handleInputChange}
            style={{ color: '#333333' }}
          />
          <TextField
            name="message"
            label="Your Message"
            variant="filled"
            multiline
            rows={4}
            fullWidth
            value={formData.message}
            onChange={handleInputChange}
            style={{ color: '#333333' }}
          />
          <Button
            type="submit"
            variant="contained"
            style={{ backgroundColor: '#5b5bf7' }}
          >
            {!isSending ? 'Send' : <ClipLoader />}
          </Button>
        </Box>
      </div>
    );
  };

  return <div className={styles.root}>{renderContactForm()}</div>;
};

export default FeedbackScreen;
