import { server } from './constants';

async function httpRequest(
  url,
  method,
  data = null,
  token = null,
  credentials = null,
  refreshAuthToken = true,
  setJsonHeaders = true
) {
  const headers = setJsonHeaders
    ? {
        'Content-Type': 'application/json',
      }
    : {};
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }
  const options = {
    method,
    headers,
    body: data ? (setJsonHeaders ? JSON.stringify(data) : data) : null,
  };
  if (method === 'GET') {
    delete options.body;
  }
  if (credentials) {
    options['credentials'] = credentials;
  }

  if (!navigator.onLine) {
    return alert('You are not connected to the internet');
  }
  console.log('Making HTTP Request. Url: ', url, ', Options: ', options);
  const response = await fetch(url, options);
  console.log(
    'Got Response for HTTP Request with Url: ',
    url,
    ', Response: ',
    response
  );

  if (
    (response.status === 401 || response.status === 403) &&
    refreshAuthToken
  ) {
    console.log('Unauthorized');
    return;
  }

  const responseData = await response.json();

  console.log('response data -> ', responseData);
  if (!response.ok) {
    return {
      error: true,
      status: response.status,
      message: responseData.message,
      errors: responseData.errors,
    };
  }
  return {
    error: false,
    success: responseData.success,
    message: responseData.message,
    data: responseData.data,
  };
}

export async function getRequest(
  url,
  token,
  credentials = null,
  refreshAuthToken = true,
  setJsonHeaders = true
) {
  const response = await httpRequest(
    server + url,
    'GET',
    null,
    token,
    credentials,
    refreshAuthToken,
    setJsonHeaders
  );
  if (response.status === 403) {
    // dispatch({ type: "LOGOUT" });
    return;
  }

  return response;
}

export async function postRequest(
  url,
  data,
  token,
  credentials = null,
  refreshAuthToken = true,
  setJsonHeaders = true
) {
  const response = await httpRequest(
    server + url,
    'POST',
    data,
    token,
    credentials,
    refreshAuthToken,
    setJsonHeaders
  );

  if (response.status === 403) {
    // dispatch({ type: "LOGOUT" });
    return;
  }

  return response;
}
