import React from 'react';
import styles from './InterviewPanelOption.module.css';

const InterviewPanelOption = (props) => {
  const handleClick = () => {
    props.handleClick(props.index, props.panelOptions, props.setPanelOptions); // Call parent function with itemId as parameter
  };
  return (
    <div
      onClick={handleClick}
      className="text-black bg-white hover:text-[#5b5bf7] hover:bg-[#faf6fe]   "
    >
      <div className="p-2">{props.panelOptionLabel}</div>
    </div>
  );
};

export default InterviewPanelOption;
