import React from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { IoCloudUploadOutline } from 'react-icons/io5';

const FileUploader = (props) => {
  const handleFileInput = (e, setFile) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
    }
  };

  const getIcon = (isDisabled) => {
    if (props.fileUploaded) {
      return (
        <div className="flex items-center space-x-2 text-[#5b5bf7]">
          <CheckCircleIcon />
          <span className="text-sm">{props.fileUploaded.name}</span>
        </div>
      );
    } else {
      return (
        <div className="text-gray-700 flex items-center space-x-2 text-xs  p-2">
          <IoCloudUploadOutline className="text-xl" />
          <span>Drag file to upload or</span>
          <span className="text-[#5b5bf7] underline">browse</span>
        </div>
      );
    }
  };

  return (
    <div>
      <div className="relative w-full border border-dotted p-4 flex justify-center items-center">
        <input
          type="file"
          accept={props.fileType}
          className="absolute inset-0 opacity-0 cursor-pointer"
          onChange={(e) => handleFileInput(e, props.setFile)}
          disabled={props.isDisabled}
        />
        {getIcon(props.isDisabled)}
      </div>
      <p className="text-gray-500 text-xs py-2 ">{props.footerText}</p>
    </div>
  );
};

export default FileUploader;
