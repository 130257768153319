import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../slices/userSlice';

import { saveState, loadState } from './StateToLocalStorageHandler';
import throttle from 'lodash.throttle';
// import thunk from "redux-thunk";
const store = configureStore({
  reducer: {
    user: userReducer,
  },
  preloadedState: loadState(),
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

store.subscribe(throttle(() => saveState(store.getState()), 1000));

export default store;
