import React from 'react';
import { IoMdCheckmarkCircle } from 'react-icons/io';
import { GoCircle } from 'react-icons/go';

const InterviewTypeTile = (props) => {
  const onClick = () => {
    if (props.disabled) return;

    const newType = props.isSelected ? '' : props.type;
    props.setInterviewType(newType);
  };

  return (
    <div
      onClick={onClick}
      className={`relative flex flex-col border p-[50px]  rounded-xl cursor-pointer -mt-[22px] ${
        props.disabled
          ? 'opacity-50 cursor-not-allowed pointer-events-none'
          : ''
      }`}
    >
      <div className="flex flex-col items-center justify-center h-full">
        <div className="absolute top-2 left-4">
          {props.isSelected ? (
            <IoMdCheckmarkCircle className="text-[#5b5bf7]" />
          ) : (
            <GoCircle />
          )}
        </div>

        <img className="h-[40px]" src={props.image} alt={props.type} />
        <div className="flex items-center justify-center">
          <p className="text-sm font-medium mt-5">{props.type}</p>
        </div>
      </div>
    </div>
  );
};

export default InterviewTypeTile;
