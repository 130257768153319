import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

const Name = ({ styles }) => {
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    console.log('the name is', user?.username);
  }, [user]);

  return (
    <div style={styles}>
      <h1 className="font-bold">Welcome {user?.username}</h1>
      <p className="text-gray-800 text-xs">Give interviews and get feedback</p>
    </div>
  );
};

export default Name;
