// src/aws-config.js
import AWS from 'aws-sdk';

const S3_BUCKET = 'deepvisionresumebucket';
const REGION = 'eu-north-1';
const accessKeyId = 'AKIA6ODU6R4JD4HQ5NO2';
const secretAccessKey = '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';

// Initialize the Amazon Cognito credentials provider
AWS.config.update({
  region: REGION,
  accessKeyId,
  secretAccessKey,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

export default myBucket;
