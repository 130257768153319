import React from 'react';
import LogoAndName from '../../../Assets/LogoAndName.png';
const Footer = () => {
  return (
    <div className="bg-[#e7e7fe] p-2 text-sm pt-10">
      <div className="">
        <img src={LogoAndName} />
      </div>
      <div className="text-sm p-2 py-7">
        <div>
          1207 /343 & 1207 /1/343/1, 9th Main, 7th Sector, HSR Layout, Bangalore
          South, Bangalore- 560102, Karnataka
        </div>
        <div>CIN: U62091KA2023PTC180209</div>
      </div>
      <div className="p-3 pt-3">
        <h1 className="font-bold py-1">Company</h1>
        <h1 className="py-1">Privacy Policy</h1>
        <h1 className="underline py-1">Terms of Use</h1>
      </div>
    </div>
  );
};

export default Footer;
