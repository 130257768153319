import { getRequest } from '../../Util/apiHelper';
import { RealtimeTranscriber } from 'assemblyai/streaming';
import * as RecordRTC from 'recordrtc';

export const getAssemblyAIToken = async (token, assemblyAITokenRef) => {
  //   if (!assemblyAITokenRef.current) {
  //     const tempToken = await getRequest(
  //       'api/v1/audio/getAssemblyAIToken',
  //       token
  //     );
  //     assemblyAITokenRef.current = tempToken.data.token;
  //   } else {
  //     console.log('Already present assembly ai token');
  //   }
  const tempToken = await getRequest('api/v1/audio/getAssemblyAIToken', token);
  assemblyAITokenRef.current = tempToken.data.token;
};

export const initializeAudioStream = async (audioStreamRef) => {
  if (!audioStreamRef.current) {
    audioStreamRef.current = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });
  } else {
    console.log('Already present audio steam');
  }
};

export const base64ToArrayBuffer = (base64) => {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
};

export const handleStartRecording = async (
  token,
  assemblyAITokenRef,
  audioStreamRef,
  realtimeTranscriber,
  setInputvalue,
  inputvalue,
  recorder,
  setIsRecording,
  isAssemblyAIConnectionSuccessfulRef
) => {
  try {
    let isTranscriberAlreadyPresent = true;
    // Fetch token and initialize audio stream if not already done
    await getAssemblyAIToken(token, assemblyAITokenRef);
    await initializeAudioStream(audioStreamRef);

    if (realtimeTranscriber.current == null) {
      console.log('Realtime transcriber is null');
      isTranscriberAlreadyPresent = false;
      // Initialize the RealtimeTranscriber
      realtimeTranscriber.current = new RealtimeTranscriber({
        token: assemblyAITokenRef.current,
        sampleRate: 16_000,
      });
      console.log('Created new realtimeTranscriber object');
    }

    const texts = {};
    realtimeTranscriber.current.on('transcript', (transcript) => {
      if (recorder.current == null) {
        console.log('Recorder is null, returning');
        return;
      }

      let msg = '';
      texts[transcript.audio_start] = transcript.text;
      const keys = Object.keys(texts).sort((a, b) => a - b);
      for (const key of keys) {
        if (texts[key]) {
          msg += ` ${texts[key]}`;
        }
      }
      setInputvalue(inputvalue + msg);
    });

    console.log('realtimeTranscriber transcripted created');

    realtimeTranscriber.current.on('error', async (event) => {
      console.log('Inside real transcriber error', event);
      setIsRecording(false);
      await realtimeTranscriber.current.close();
      realtimeTranscriber.current = null;
      isAssemblyAIConnectionSuccessfulRef.current = false;
    });

    console.log('realtimeTranscriber error created');

    realtimeTranscriber.current.on('close', async (code, reason) => {
      console.log(`Connection closed: ${code} ${reason}`);
      realtimeTranscriber.current = null;
      isAssemblyAIConnectionSuccessfulRef.current = false;
    });

    console.log('realtimeTranscriber close created');

    if (!isTranscriberAlreadyPresent) {
      console.log('Connecting to websocket');
      // Connect the RealtimeTranscriber
      await realtimeTranscriber.current.connect();
      isAssemblyAIConnectionSuccessfulRef.current = true;
      console.log('Connecting to websocket :: connected');
    }

    // Set up recording with the already available audio stream
    recorder.current = new RecordRTC(audioStreamRef.current, {
      type: 'audio',
      mimeType: 'audio/webm;codecs=pcm',
      recorderType: RecordRTC.StereoAudioRecorder,
      timeSlice: 250,
      desiredSampRate: 16000,
      numberOfAudioChannels: 1,
      bufferSize: 4096,
      audioBitsPerSecond: 128000,
      ondataavailable: async (blob) => {
        if (!realtimeTranscriber.current) return;
        const buffer = await blob.arrayBuffer();
        try {
          if (isAssemblyAIConnectionSuccessfulRef.current) {
            realtimeTranscriber.current.sendAudio(buffer);
          } else {
            console.log('isAssemblyAIConnectionSuccessfulRef is false');
          }
        } catch (err) {
          console.log('IN ondataavailable, error: ', err);
          realtimeTranscriber.current = null;
          isAssemblyAIConnectionSuccessfulRef.current = false;
          await handleStartRecording(
            token,
            assemblyAITokenRef,
            audioStreamRef,
            realtimeTranscriber,
            setInputvalue,
            inputvalue,
            recorder,
            setIsRecording,
            isAssemblyAIConnectionSuccessfulRef
          );
          return;
          //realtimeTranscriber.current.sendAudio(buffer);
        }
      },
    });
    recorder.current.startRecording();
    setIsRecording(true);
  } catch (error) {
    console.error(error);
  }
};

export const handleStopRecording = async (
  realtimeTranscriber,
  recorder,
  isRecording,
  setIsRecording,
  finalElimination
) => {
  try {
    if (finalElimination && realtimeTranscriber.current) {
      await realtimeTranscriber.current.close();
      realtimeTranscriber.current = null;
      //isAssemblyAIConnectionSuccessfulRef.current = false
    }

    if (recorder.current) {
      recorder.current.pauseRecording();
      recorder.current = null;
    }

    if (isRecording) {
      setIsRecording(false);
    }
  } catch (error) {
    console.error(error);
  }
};
