import tenK from './../Assets/tenK.png';
import fifteenK from './../Assets/fifteenK.png';
import MaterialIcon from './../Assets/MaterialIcon.png';
import iconmoon from './../Assets/iconmoon.png';
import HealthyLifeatHome from './../Assets/HealthyLifeatHome.png';
import KnowledgeIncrease from './../Assets/KnowledgeIncrease.png';
import Healthylifestyleathome2 from './../Assets/Healthylifestyleathome2.png';
import Healthylifestyleathome3 from './../Assets/Healthylifestyleathome3.png';
import ConfidenceBoost from './../Assets/ConfidenceBoost.png';
import man_comp1 from './../Assets/man_comp1.png';
import man_comp3 from './../Assets/man_comp3.png';
import film from './../Assets/film.png';
export const BenefitP1 = [
  {
    id: 1,
    img: tenK,
    headings: 'Real interview questions',
    para: 'Trained on 10,000+ real interview questions, prepare for interviews with our AI model tailored to specific job description.',
  },
  {
    id: 2,
    img: MaterialIcon,
    headings: 'Easy Interactive interviews',
    para: 'Interact with our AI avatar using cutting-edge speech-to-text tech for dynamic interviews and insightful follow-up questions.',
  },
];

export const BenefitP12 = [
  {
    id: 3,
    img: iconmoon,
    headings: 'Component level feedback',
    para: 'Get five key feedback components for each question: Overall Assessment, Question Breakdown, Answer Criteria, STAR Review, and Improvement Tips.',
  },
  {
    id: 4,
    img: fifteenK,
    headings: 'Major domains covered',
    para: 'Get five key feedback components for each question: Overall Assessment, Question Breakdown, Answer Criteria, STAR Review, and Improvement Tips.',
  },
];

export const PracticeMockData = [
  {
    id: 1,
    heading: 'Select your job profile',
    para: 'Choose your target job profile for the resume driven personalised interview.',
  },
  {
    id: 2,
    heading: 'Upload your resume',
    para: 'Give your resume for customised questions based on your background',
  },
  {
    id: 3,
    heading: 'Upload the job description',
    para: 'Give the job description to align questions with the role’s requirements.',
  },
  {
    id: 4,
    heading: 'Start your mock interview',
    para: 'Start the interview, receive real-time questions and practice your responses.',
  },
];

export const ProblemSolvesData = [
  {
    id: 1,
    img: man_comp1,
    heading: 'Master Key Concepts with Interviews',
    para: 'Users gain in-depth understanding of key concepts through targeted interview questions and real-time feedback.',
  },
  {
    id: 2,
    img: Healthylifestyleathome2,
    heading: 'Tackle Real-World Challenges',
    para: 'Practice sessions simulate real-world challenges, helping users develop effective strategies and solutions.',
  },
  {
    id: 3,
    img: man_comp3,
    heading: 'Boost Confidence with Realistic Practice',
    para: 'Regular practice with realistic simulations and instant feedback boosts confidence and readiness for real interviews.',
  },
];

export const interviewOptions = [
  {
    type: 'Computer Vision',
    image: film,
    coupon: '1',
    jd: 'about computer vision developement',
  },
  {
    type: 'Data Science',
    image: film,
    coupon: '2',
    jd: 'about data science',
  },
  {
    type: 'Machine Learning',
    image: film,
    coupon: '3',
    jd: 'about ML',
  },
  {
    type: 'Product Manager',
    image: film,
    coupon: '4',
    jd: 'about product manager',
  },
  {
    type: 'Robotics',
    image: film,
    coupon: '5',
    jd: 'about Robotics',
  },
  {
    type: 'Software Development',
    image: film,
    coupon: '6',
    jd: 'about SDE',
  },
  {
    type: 'UX',
    image: film,
    coupon: '7',
    jd: 'about UX',
  },
  {
    type: 'Other',
    image: film,
    coupon: '8',
    jd: 'get info from resume',
  },
];
